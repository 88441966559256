@use './scss/' as *;

/**
  * Name: ICOLand | ICO landing page & ICO Crypto HTML Template
  * Version: 1.0.2
  * Author: Themesflat
  * Author URI: http://www.themesflat.com
*/


@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

@font-face {
    font-family: 'resobot-bold';
    src: url('./assets/fonts/BitBlox_Monospaced.otf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

html {
    margin-right: 0 !important;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

div#root {
    overflow: hidden;
}

body {
    font-family: 'resobot-bold', sans-serif;
    font-size: 10px;
    line-height: 21px;
    font-weight: 500;
    color: $txt-color;
    background: url('assets/images/backgroup/bgnew.gif');

    .home2 {
        .bg_body {
            position: relative;

            .bg_h2 {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;

                img {
                    height: 100%;
                    opacity: 0.5;
                    background: linear-gradient(180deg, #09121D 3.92%, rgba(9, 18, 29, 0) 36.79%, #09121D 83.98%);
                    mix-blend-mode: luminosity;
                }
            }
        }

    }
}

p {
    font-family: 'resobot-bold', sans-serif;
    font-size: 15px;
    line-height: 21px;
    font-weight: 500;
    color: $txt-color;
}

img {
    max-width: 100%;
    height: auto;
    transform: scale(1);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'resobot-bold';
    font-weight: 700;
    color: $white;
}

h1,
.h1 {
    font-size: 72px;
    line-height: 1;

    @include res767 {
        font-size: 40px;
    }
}

h2,
.h2 {
    font-size: 40px;
    line-height: 1.1;
}

h3,
.h3 {
    font-size: 34px;
    line-height: 1.1;
}

h4,
.h4 {
    font-size: 22px;
    line-height: 1.4;
}

h5,
.h5 {
    font-size: 19px;
    line-height: 1.4;
}

h6,
.h6 {
    font-size: 16px;
    line-height: 1.72;
}

.container {
    max-width: 1210px;

    &.w_1690 {
        max-width: 1690px;

        .container_inner {
            position: relative;
            padding: 0 72px;
        }
    }

    &.full {
        max-width: 100%;
    }

    &.w_1280 {
        max-width: 1280px;

        .container_inner {
            position: relative;
            padding: 0 70px;

            @include res767 {
                padding: 0 15px;
            }
        }
    }

    &.w_1490 {
        max-width: 1490px;
    }

    &.w_1342 {
        max-width: 1342px;
    }

    &.w_1710 {
        max-width: 1920px;
        padding-left: 120px;
        padding-right: 120px;
    }

    &.w_1850 {
        max-width: 1850px;

        .container_inner {
            position: relative;
            padding: 0 94px;
        }
    }

    &.w_1320 {
        max-width: 1320px;

        .container_inner {
            position: relative;
            padding: 0 90px;

            @include res767 {
                padding: 0 15px;
            }
        }
    }

    &.w_1310 {
        max-width: 1310px;

        .container_inner {
            position: relative;
            padding: 0 84px;
        }
    }
}

.home2 .project2 {
    .container {
        &.w_1280 {
            max-width: 1280px;

            .container_inner {
                position: relative;
                padding: 0 70px;

                @include res767 {
                    padding: 0 15px;
                }
            }
        }
    }
}

.text {
    font-family: "Space Grotesk", sans-serif;
    font-size: 15px;
    line-height: 21px;
    font-weight: 500;
    color: #798DA3;
}

.z-index {
    z-index: 999;
}

#wrapper {
    width: $w100;
    height: $w100;
    overflow: hidden;
    position: relative;
}

.row {
    margin-right: -15px;
    margin-left: -15px;
}

ul,
li {
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

a {
    text-decoration: none;
    color: unset;
    transition: all .3s;
    cursor: pointer;
    display: inline-block;

    &:hover {
        color: $color-hover;
        transition: all .3s;
    }
}

.no-wallet-connected {
    margin-top: 300px;
    width: 100%;
    text-align: center;
}

.no-wallet-connected h4 {
    color: #38ECF3;
}

.no-wallet-connected h2 {
    color: #38ECF3;
}

.no-assets {
    margin-top: 150px;
    width: 100%;
    text-align: center;
}

.no-assets h4 {
    color: #38ECF3;
}

.no-assets h2 {
    color: #38ECF3;
}

.center {
    text-align: center;
}

.container {
    width: 100%;
    margin: auto;
}

.container-fluid,
.container {
    padding-left: 15px;
    padding-right: 15px;
}

.wrap-fx-30 {
    @include flex2;
    margin-left: -30px;

    .icon-box {
        width: calc(33.3333% - 30px);
        margin-left: 30px;
    }
}

.no-pd-top {
    padding-top: 0;
}

.m-b17 {
    margin-bottom: 17px;
}

.p-b42 {
    padding-bottom: 42px;
}

.m-b40 {
    margin-bottom: 40px;
}

.m-r-40 {
    margin-right: -40px;
}

.p-l150 {
    padding-left: 150px;
}

.m-t30 {
    margin-top: 30px;
}

.p-l70 {
    padding-left: 70px;
}

.mt-48 {
    margin-top: 48px;
}

.ml30-fx {
    margin-left: -30px;
}

.mb-0 {
    margin-bottom: 0;
}

.m-b30 {
    margin-bottom: 30px;
}

.m-b51 {
    margin-bottom: 51px;
}

.p-r60 {
    padding-right: 60%;
}

.m-b60 {
    margin-bottom: 60px;
}

.m-b61 {
    margin-bottom: 61px;
}

.m-b26 {
    margin-bottom: 26px;
}

.m-b6 {
    margin-bottom: 6px;
}

.m-t12 {
    margin-top: 12px;
}

.m-b16 {
    margin-bottom: 16px;
}

.m-t16 {
    margin-top: 16px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mt-40 {
    margin-top: 40px;
}

.pl-r-0 {
    padding-left: 0;
    padding-right: 0;
}

.p-b23 {
    padding-bottom: 23px;
}

.boder-bt-st1 {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.txt-tranf-cap {
    text-transform: capitalize;
}

.m-t60 {
    margin-top: 60px;
}

.m-b50 {
    margin-bottom: 50px;
}

.m-b48 {
    margin-bottom: 48px;
}

.m-t30 {
    margin-top: 30px;
}

.m-t40 {
    margin-top: 40px;
}

.pd20 {
    padding: 0 20%;
}

.pd22 {
    padding: 0 22%;
}

.m-b51 {
    margin-bottom: 51px;
}

.pd30 {
    padding: 0 30%;
}

.bg-st1 {
    background: $bg-3 !important;
}

.bg-st2 {
    background: $bg-main !important;
}

.wrap-fx {}

.wrap-fx2 {
    @include flex(center, center);
    position: relative;
}

select {
    background-color: $bg-2;
    border: none;
    font-family: $font-body;
    outline: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-size: 15px;
    line-height: 21px;
    border-radius: 0;
    height: 46px;
    width: 100%;
    color: $txt-color;
    padding: 12px 14px;
    border-radius: 8px;
    @include flex(center, flex-start);
}

// form //
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
    border: none;
    font-family: $font-body;
    outline: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-size: 15px;
    line-height: 21px;
    border-radius: 0;
    height: 46px;
    width: 100%;
    background: $bg-3;
    color: $txt-color;
    padding: 12px 14px;
    border-radius: 8px;
    @include flex(center, flex-start);
}

textarea::placeholder,
input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder,
input[type="datetime"]::placeholder,
input[type="datetime-local"]::placeholder,
input[type="date"]::placeholder,
input[type="month"]::placeholder,
input[type="time"]::placeholder,
input[type="week"]::placeholder,
input[type="number"]::placeholder,
input[type="email"]::placeholder,
input[type="url"]::placeholder,
input[type="search"]::placeholder,
input[type="tel"]::placeholder,
input[type="color"]::placeholder {
    font-family: $font-body;
    color: $txt-color;
}

form {
    button {
        border: none;
        outline: none;
    }
}

.form-seach {
    position: relative;
    @include flex(center, flex-start);

    input {
        padding-left: 48px;
    }

    svg {
        position: absolute;
        left: 17px;
    }
}



/* Scroll Top
-------------------------------------------------------------- */
#scroll-top {
    //box-shadow: 0 0 10px #FF0175;
    position: fixed;
    display: block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 4px;
    text-align: center;
    z-index: 999;
    right: 25px;
    bottom: 25px;
    border-radius: 0% !important;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
}

#scroll-top.show {
    right: 15px;
    opacity: 1;
    visibility: visible;
}

#scroll-top:before,
#scroll-top:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include transition3;
}

#scroll-top:before {
    z-index: -1;
    background: #FF0175;
}


#scroll-top:after {
    content: "\f077";
    font-family: "Font Awesome 5 Pro";
    font-size: 18px;
    color: #fff;
    font-weight: 600;
}

#scroll-top:hover {
    transform: translateY(-7%);
}


/* PreLoad
-------------------------------------------------------------- */
.preloader {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.clear-loading {
    text-align: center;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
}

.loading-effect-2 {
    width: 100px;
    height: 100px;
}

.loading-effect-2>span,
.loading-effect-2>span:before,
.loading-effect-2>span:after {
    content: "";
    display: block;
    border-radius: 50%;
    border: 2px solid $color-hover;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.loading-effect-2>span {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-left-color: transparent;
    -webkit-animation: effect-2 2s infinite linear;
    -moz-animation: effect-2 2s infinite linear;
    -ms-animation: effect-2 2s infinite linear;
    -o-animation: effect-2 2s infinite linear;
    animation: effect-2 2s infinite linear;
}

.loading-effect-2>span:before {
    width: 75%;
    height: 75%;
    border-right-color: transparent;
}

.loading-effect-2>span:after {
    width: 50%;
    height: 50%;
    border-bottom-color: transparent;
}

@-webkit-keyframes effect-2 {
    from {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes effect-2 {
    from {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.img_main {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.assetWrapper {
    width: auto;
    display: contents;
}

.empty-response {
    margin-top: 180px;
}

.mt-100 {
    margin-top: 100px;
}

ul.guide {
    list-style-position: outside !important;
    margin-left: 50px;
}

ul.guide li {
    font-size: 25px;
    color: #FFF;
    line-height: 1.4;
    list-style: decimal !important;
}

ul.guide li::marker {
    font-size: 25px;
    color: #fff;
}

a.tf-button.stake_btn:hover {
    scale: 1.05;
}

button.swal2-confirm.swal2-styled {
    border-radius: 0px !important;
    font-size: 20px !important;
    width: 120px;
    background: #FF0175 !important;
}

button.swal2-deny.swal2-styled {
    border-radius: 0px !important;
    font-size: 20px !important;
    width: 120px;
    background: #FF0175 !important;
}

button.swal2-confirm.swal2-styled:hover {
    scale: 1.05;
}

button.swal2-deny.swal2-styled:hover {
    scale: 1.05;
}

button.swal2-cancel.swal2-styled.swal2-default-outline{
    border-radius: 0px !important;
    font-size: 20px !important;
    width: 120px;
    background: #0209c9 !important;
}

.swal2-popup.swal2-modal.swal2-show {
    color: #FFF;
    background-color: #0007FF;
    padding: 30px 10px !important;
    font-size: 20px;
    border-radius: 0px;
    border: 5px solid #FF0175;
}

div:where(.swal2-container) div:where(.swal2-loader) {
    display: none;
    align-items: center;
    justify-content: center;
    width: 2.2em;
    height: 2.2em;
    margin: 0 1.875em;
    animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
    border-width: 0.25em;
    border-style: solid;
    border-radius: 100%;
    border-color: #38ECF3 rgba(0, 0, 0, 0) #FFF rgba(0, 0, 0, 0);
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 50px;
}

.lds-ellipsis div {
    position: absolute;
    top: 15px;
    width: 13px;
    height: 13px;
    background: #38ECF3;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}

.no-available-asset {
    margin-top: 150px;
    width: 100%;
    text-align: center !important;
}

.no-nft {
    color: #38ECF3;
}

a.tf-button.stake_btn.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.7;
    background: #4c4c4c;
}

a.tf-button.stake_btn.disabled:hover {
    scale: 1;
}

.nft_disabled {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 4;
}

.nft_synching {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 4;
}

h3.nft_stabilizing {
    margin-top: 50%;
    text-align: center;
    height: 100%;
    width: 100%;
    z-index: 5;
    position: absolute;
    vertical-align: middle;
}

.image h3 {
    margin-top: 20px;
    width: 100%;
    text-align: right;
    font-size: 19px;
    padding-right: 20px;
    position: absolute;
    z-index: 2;
    top: 0;
}

.image h2 {
    color: #86FF00;
    margin-top: 45px;
    width: 100%;
    text-align: right;
    font-size: 30px;
    padding-right: 20px;
    position: absolute;
    z-index: 2;
    top: 0;
}

.image h4 {
    width: 100%;
    text-align: left;
    font-size: 27px;
    padding-left: 20px;
    position: absolute;
    z-index: 2;
    bottom: 0;
    //background: rgba(0,0,0,0.8);
}

.image h5 {
    padding-left: 20px;
    width: 100%;
    text-align: left;
    font-size: 22px;
    margin-bottom: 40px;
    position: absolute;
    z-index: 2;
    bottom: 0;
}

h2.nft_title {
    width: 100%;
    text-align: center;
    top: 0;
    padding-top: 20px;
    font-size: 22px;
    z-index: 2;
}

.staked-stats {
    margin-bottom: 25px;
    border: 5px solid #FF0175;
    padding: 30px 30px 10px 30px;
    background: #0007FF;
}

.staked-stats h5 {
    font-size: 20px;
}

.mb-100 {
    padding-bottom: 230px;
}

.loader {
    width: 58px;
    height: 58px;
    border: 5px solid #38ECF3;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 0.5s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader-wallet {
    margin-top: 6px;
    width: 20px;
    height: 20px;
    border: 3px solid #38ECF3;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 0.5s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader-stats {
    margin-bottom: 10px;
    margin-top: -10px;
    width: 55px;
    height: 55px;
    border: 5px solid #38ECF3;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 0.5s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.mobile-text{
    display: none;
}
.desktop-text{
    display: block;
}

a.tf-button.stake_btn.filter {
    vertical-align: middle;
    padding: 0px 20px;
    /* height: 10px; */
    font-size: 18px;
    margin-right: 15px;
    width: auto;
    float: left;
}

a.tf-button.stake_btn.buy_btn {
    float: none !important;
    text-align: center !important;
    width: auto;
    font-size: 22px;
}

p.days_staked {
    padding: 10px;
    width: auto;
    background: rgba(0,0,0,0.5);
    position: absolute;
    z-index: 2;
    color: #FFF;
    font-size: 14px !important;
}

p.stabilizing_days {
    color: #FFF;
    margin-top: 60%;
    text-align: center;
    height: 100%;
    width: 100%;
    z-index: 5;
    position: absolute;
    font-size: 19px;
    vertical-align: middle;
}

.steps-highlight {
    color: #FF0175;
}

.guide-steps h4 {
    color: #38ECF3;
}

img.kwic_token_icon {
    width: 40px;
}

.content-progress-box.style2 {
    padding-top: 10px;
}

#main-nav .menu li a {
    font-size: 18px !important;
}

#main-nav .menu li {
    margin-right: 10px !important;
}