.fpvlNK {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 15rem;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    border-width: 1px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 400;
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}

.bqaZfE {
    margin: 0.5rem;
    height: 1.5rem;
}

.fQlGkr {
    position: absolute;
    width: 15rem;
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
    border-width: 1px;
    text-align: center;
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
    --tw-backdrop-blur: blur(8px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    display: none;
}

.EGDgF {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    opacity: 0.8;
}

.bsGwOp {
    margin: 0.25rem;
    height: 2rem;
    padding-right: 0.5rem;
}

.jWnuMx {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 400;
}

/* Add this CSS to your stylesheet */
.CardanoWallet__StyledMenuList-sc-13vl5pq-1 {
    display: none; /* Initially hide the menu list */
    position: absolute;
    width: 15rem;
     /* Position it below the Menu Button */
    background-color: #fff; /* Set your desired background color */
    z-index: 1; /* Ensure it's above other elements */
  
    /* Add other styling properties as needed */
  }
  
  .CardanoWallet__StyledMenuButton-sc-13vl5pq-0:hover + .CardanoWallet__StyledMenuList-sc-13vl5pq-1,
  .CardanoWallet__StyledMenuList-sc-13vl5pq-1:hover {
    display: block;
    /* Additional styling properties if needed */
  }

  .MenuItem__StyledItem-sc-obisy3-0:hover {
    opacity: 1;
  }

  .getLovelace {
    font-size: 19px;
  }

  .cardanoSymbol{
    font-size: 15px;
  }

  .walletValue {
    margin-top: 3px;
  }