@use '../../scss/' as *;

/* button
-------------------------------------------------------------- */
.wrap-btn {
    @include flex2;
}
.tf-button {
    display: table-cell;
    text-align: center;
    width: 100%;
    height: 52px;
    margin: 0;
    font-family: 'resobot-bold';
    -webkit-clip-path: polygon(var(--path));
    clip-path: polygon(var(--path));
    background-size: 200% 100%;
    background-position: 0% 0%;
    font-size: 19px;
    line-height: 50px;
    text-transform: uppercase;
    @include transition3;

    &.style1 {
        background: #FF0175;
        color: $white;

        &:hover {
            background: #990046;
            color: #FFF;
        }
    }

    &.style2 {
        background: #86FF00;
        color: #000000;

        &:hover {
            background: #798DA3;
            color: $white;
        }
    }

    &.style3 {
        background: #86FF00;
        color: #000000;
        height: 60px;
        font-size: 16px;

        &:hover {
            background: #798DA3;
            color: $white;
        }
    }

    &.stake_btn {
        display: inline-table;
        float: right;
        padding: 5px 20px;
        color: #FFF;
        background: #FF0175;
        font-size: 26px;
    }

    &.unstake_btn {
        display: inline-table;
        float: right;
        padding: 5px 20px;
        color: #FFF;
        background: #FF0175;
        font-size: 26px;
    }
}
